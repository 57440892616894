import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DealersService } from 'app/_services/dealers.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrdersService } from 'app/_services/orders.service';


@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.scss']
})
export class DealersComponent implements OnInit {

  dealersList: any = [];
  start: any = 0;
  page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading: boolean = false;
  public dealerDetails: any;
  public loadingPayment: boolean = false;
  public loadingFrmPayment: boolean = false;
  public errorPayment = '';
  public newFormPayment: FormGroup;
  public submittedPayment: boolean = false;
  public paymentList: any = [];
  public startPayment: any = 0;
  public pagePayment: any = 0;
  public collectionSizePayment = 0;
  public lengthsPayment = 5;
  public delerId: number = 0;
  public dealerKey: number = 0;
  public delerdetails: any = [];
  public progressMainTable:boolean = false;
  public progressPaymentTable:boolean = false;  
  public pagePaymentBasicText = 1;

  public employeeDropDown: any = [];
  public selectBasicLoading = false;
  public employeeFilter: any = '';
  public searchByName: any = '';

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private ordersService: OrdersService, private clipboardApi: ClipboardService, private _toastrService: ToastrService, private http: HttpClient, private dealersService: DealersService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loading = true;
    this.dealerList(this.start, this.lengths);
    this.getEmployeeDropDown();

    this.newFormPayment = this._formBuilder.group({
      payment_choice: ['', Validators.required],
      paying_amount: ['', Validators.required],
      remarks: [''],
    });

  }
  getEmployeeDropDown() {
    this.ordersService.getEmployeeList().subscribe(
      data => {
        this.selectBasicLoading = true;
        if (data.status) {
          this.selectBasicLoading = false;
          this.employeeDropDown = data.employee_data;
        } else {
          this._toastrService.error(data.errorMessage, 'Opps!',);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  get f() {
    return this.newFormPayment.controls;
  }

  dealerList(my_start: any, my_lengths: any) {
    this.dealersService.getData(my_start, my_lengths, '',this.employeeFilter,this.searchByName).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.dealersList = response.data;
          this.collectionSize = response.recordsTotal;
          this.delerdetails = this.dealersList[this.dealerKey];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.dealersService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.dealerList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Customer Deleted Successfully ',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  reloadList() {
    this.progressMainTable = true;
    this.dealerList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.dealerList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.dealerList(this.start, this.lengths);
  }

  /**
  * Toggle the sidebar
  *
  * @param name
  */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  copyText(url: any) {
    this.clipboardApi.copyFromContent('https://order.digitalorderbook.com/#/home?key=' + url);
    this._toastrService.success('', 'Copied!', { toastClass: 'toast ngx-toastr', closeButton: true });
  }

  // modal Basic
  modalOpen(modalBasic, dealerId: any) {
    this.dealerDetails = [];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });
    this.dealersService.dealerViewDetails(dealerId).subscribe(
      response => {
        if (response.status) {
          this.dealerDetails = response.dealer;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  paymentAdd(delerId: any, index: any) {
    this.dealerKey = index;
    this.delerId = delerId;
    this.paymentList = [];
    this.collectionSizePayment = 0;
    this.newFormPayment.reset();
    this.delerdetails = this.dealersList[index];
    this.toggleSidebar('new-sidebar');
    this.f.payment_choice.setValue('');
    this.f.paying_amount.setValue('');
    this.progressPaymentTable = true;
    this.getPaymentList(this.startPayment, this.lengthsPayment, delerId);
  }

  getPaymentList(my_start: any, my_lengths: any, delerId: any) {
    this.loadingPayment = true;
    this.progressPaymentTable = true;
    this.dealersService.getPaymentListById(my_start, my_lengths, delerId).subscribe(
      response => {
        this.loadingPayment = false;
        this.progressPaymentTable = false;
        if (response.status) {
          this.paymentList = response.data;
          this.collectionSizePayment = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  loadPaymentPage(event: any) {
    this.progressPaymentTable = true;
    this.pagePayment = this.lengthsPayment * (event - 1);
    this.getPaymentList(this.pagePayment, this.lengthsPayment,this.delerId);
  }

  /**
   * Submit
   *
   * @param form
   */
  onSubmitPayment() {

    this.submittedPayment = true;
    // stop here if form is invalid
    if (this.newFormPayment.invalid) {

    }
    else {
      this.loadingFrmPayment = true;

      this.dealersService.addPayment(this.delerId, this.f.payment_choice.value, this.f.paying_amount.value, this.f.remarks.value).subscribe(
        data => {
          this.loadingFrmPayment = false;
          if (data.status) {
            this.delerId = 0
            this.submittedPayment = false;
            this.newFormPayment.reset();
            this.toggleSidebar('new-sidebar');
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
          }
          else {
            this._toastrService.error(data.error.sales_price, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  deletePayment(id: any,index:any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.dealersService.deletePayment(id).subscribe(
          response => {
            if (response.status) {
              me.progressPaymentTable = true;
              me.getPaymentList(me.startPayment, me.lengthsPayment, me.delerId);
              me.reloadList();
              
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Payment Deleted Successfully ',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.error,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  getEmployeeFilter(employee) {
    console.log(employee.emp_id);
    this.employeeFilter = employee.emp_id;
    this.reloadList();
  }


  getNameFilter(searchByName) {
    this.searchByName = searchByName;
    this.reloadList();
  }
}

